import React, { useState, useEffect } from 'react';
import { Card, Button, Container, Row, Col } from 'react-bootstrap';
import { useAuth } from "../components/AuthContext";
import BronzeTicketImage from "../assets/img/bronze-ticket.png";
import GoldTicketImage from "../assets/img/gold-ticket.png";
import DiamondTicketImage from "../assets/img/diamond-ticket.png"; 
import { Modal, Form } from 'react-bootstrap';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const selectImageByTier = (tier) => {
  switch (tier) {
    case 'Bronze':
      return BronzeTicketImage;
    case 'Gold':
      return GoldTicketImage;
    case 'Diamond':
      return DiamondTicketImage;
    default:
      return ''; // Default image or keep it empty
  }
};

const getEntryFeeByTier = (tier, tickets) => {
  if (tickets < 1) {
    return "FREE";
  } else {
    switch (tier) {
      case 'Copper':
        return "1 Copper Ticket";
      case 'Bronze':
        return "1 Bronze Ticket";
      case 'Silver':
        return "1 Silver Ticket";
      case 'Gold':
        return "1 Gold Ticket";
      case 'Diamond':
        return "1 Diamond Ticket";
      case 'Platinum':
        return "1 Platinum Ticket";
      default:
        return ''; // Default image or keep it empty
    }
  }
};

const ApplyModal = ({ show, onHide, tournament }) => {
  const { profileData, isAuthenticated } = useAuth();
  const { token } = useAuth();

  const applyToTournament = async (tournamentId) => {
    if (!isAuthenticated) {
      toast.error('You must register or log in to apply to a tournament');
      console.log('User is not authenticated');
    } else {
      console.log('ApplyModal id', profileData.id);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/apply/${tournamentId}`, {
          method: 'POST',
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            tournamentId: tournamentId,
            }),
      });

      if (response.ok) {
          toast.success('Applied successfully');
          console.log('Applied successfully');
      } else {
          const error = await response.json(); // This converts the JSON response into a JavaScript object
          toast.error(error.message);
          console.log(error.message)
          console.error('Failed to apply:', error.message);
      }
    }
};
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Call the apply to tournament API with the selected options
    // This is a placeholder function. You'll implement the API call based on your setup.
    // For example: await applyToTournament(tournament.id, { applyAs, selectedTeam });
    await applyToTournament(tournament.id);
    onHide(); // Close the modal
  };

  return (
    <>
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-black">Apply to {tournament.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
        <Form.Group>
            Apply to tournament {tournament.title}? <br />
            Entry fee is {getEntryFeeByTier(tournament.tier, tournament.requiredTickets)} <br />
            Tournament Tier is {tournament.tier} <br />
          </Form.Group>
          <Form.Group>
            <br />
          </Form.Group>
          <Form.Group>
            <Button variant="primary" type="submit">Apply</Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
    </>
  );
};

const TournamentCard = ({ tournament }) => {
  const [timeLeft, setTimeLeft] = useState('');
  const [showModal, setShowModal] = useState(false);

  const imageUrl = selectImageByTier(tournament.tier);
  const entryFeeByTier = getEntryFeeByTier(tournament.tier, tournament.requiredTickets);
  const formattedStartTime = new Date(tournament.start_time * 1000).toLocaleString();
  const { isAuthenticated, user } = useAuth();

  const cardStyle = {
    boxShadow: '0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19)',
    marginBottom: '.25rem',
    //backgroundImage: `url(${gameImageUrl})`, // Set the background image using the gameImageUrl
    backgroundSize: 'cover', // Ensure the background covers the entire card
    backgroundPosition: 'center', // Center the background image
    backgroundRepeat: 'no-repeat', // Do not repeat the background image
    color: '#ffffff', // Adjust text color for better visibility against the background
    border: '1px solid #ffffff', // Add this line to set the border color to white
  };

  const cardHeaderStyle = {
    boxShadow: '0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19)',
    marginBottom: '.25rem',
    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url(${imageUrl})`,
    backgroundSize: 'cover', // Ensure the background covers the entire card
    backgroundPosition: 'center', // Center the background image
    backgroundRepeat: 'no-repeat', // Do not repeat the background image
    color: '#02ffa8', // Adjust text color for better visibility against the background 02ffa8  00b4ff
    backgroundColor: '#000'
  }; 

  const cardFooterStyle = {
    boxShadow: '0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19)',
    marginBottom: '.25rem',
    //backgroundImage: `url(${gameImageUrl})`, // Set the background image using the gameImageUrl
    backgroundSize: 'cover', // Ensure the background covers the entire card
    backgroundPosition: 'center', // Center the background image
    backgroundRepeat: 'no-repeat', // Do not repeat the background image
    color: '#00b4ff', // Adjust text color for better visibility against the background
    backgroundColor: '#000',
  };

  const handleRegisterClick = () => {
    if (!isAuthenticated) {
      toast.error('You must register or log in to apply to a tournament'); // Display the toast error message
      console.log('User is not authenticated');
    } else {
      setShowModal(true); // Show the apply modal if the user is authenticated
      console.log('user', user);
    }
  };

  useEffect(() => {
    const calculateTimeLeft = () => {
      const difference = +new Date(tournament.start_time * 1000) - +new Date();
      let timeLeft = '';
  
      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / 1000 / 60) % 60);
        const seconds = Math.floor((difference / 1000) % 60);
  
        timeLeft = `${days}d ${hours}h ${minutes}m ${seconds}s`;
      }
  
      return timeLeft;
    };    
    setTimeLeft(calculateTimeLeft());

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [tournament.start_time]);

  return (
    <Card className="content__title card-hover-effect text-center mb-4 shadow" style={cardStyle}>
      <Card.Img variant="top" src={tournament.img} style={{ 
          height: '12%',
          objectFit: 'cover',
          display: 'block', // This is usually default for images
          marginLeft: 'auto', 
          marginRight: 'auto',
          marginTop: '5px',
          marginBottom: '5px',
          width: '80%'
        }} />
      <Card.Header style={cardHeaderStyle}>{tournament.title}<br/>{tournament.tier} {tournament.type}<br/>{tournament.tournament_type}</Card.Header>

      <Card.Body className="text-center">
        <Card.Title className="content__title"></Card.Title>
        <Card.Img variant="top" src={tournament.altimg} style={{height: '25%', width: '25%', display: 'block', marginLeft: 'auto', marginRight: 'auto', }} />
        <Card.Text className="content__title" style={{color: "#00b4ff"}}>
          {formattedStartTime} <br />
          {timeLeft && `${timeLeft}`} {tournament.gameName} <br /> 
          Region: {tournament.region} <br />
          Platform: {tournament.platform} <br />
          Entry Fee: {entryFeeByTier} <br />
          Team Slots: {tournament.max_total_teams} <br />
          Teams Registered: {tournament.total_registered} <br />
        </Card.Text>

        <Button variant="primary" onClick={handleRegisterClick}>Register</Button>
      </Card.Body>
      <ApplyModal
        show={showModal}
        onHide={() => setShowModal(false)}
        tournament={tournament}
      />
      <Card.Footer className="" style={cardFooterStyle} >{tournament.prize_pool} Prize</Card.Footer>
    </Card>
  );
};

const TournamentsList = ({ tournaments }) => {
  return (
    <Container>
      <Row>
        {tournaments.map((tournament, index) => (
          <Col key={index} md={4}>
            <TournamentCard tournament={tournament} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export const Tournaments = () => {
  const [tournaments, setTournaments] = useState([]);

  useEffect(() => {
    // Replace '/api/tournaments' with the actual endpoint from which you want to fetch the tournaments
    fetch(`${process.env.REACT_APP_API_URL}/get_active_tournaments`)
      .then((response) => response.json())
      .then((data) => {
        setTournaments(data);
      })
      .catch((error) => {
        console.error('Error fetching tournaments:', error);
      });
  }, []);
 return (
      <div className="content-layout">
        <h1 id="page-title" className="ailerons brand-color-blue content__title">
          Current Tournaments
        </h1>
        <ToastContainer />
        <div className="content__body">
          <div id="page-description">
            <span>
              <TournamentsList tournaments={tournaments} />
            </span>
          </div>
        </div>
      </div>
  );
};

export default Tournaments;
