import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from "react-router-dom";
import { NavRoutes } from "../navroutes";

//My imports
import Leaderboards from "./Leaderboards";
import Tournaments from "./Tournaments";
import Settings from "./Settings";
import Admin from "./Admin";
import VerifyEmail from "./VerifyEmail";

// Import all the components
import Presentation from "./Presentation";
import Upgrade from "./Upgrade";
import DashboardOverview from "./dashboard/DashboardOverview";
import Transactions from "./Transactions";
import BootstrapTables from "./tables/BootstrapTables";
import Signin from "./Signin";
import Signup from "./Signup";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import Lock from "./Lock";
import NotFoundPage from "./NotFound";
import ServerError from "./ServerError";

// Import components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";
import Accordion from "./components/Accordion";
import Alerts from "./components/Alerts";
import Badges from "./components/Badges";
import Breadcrumbs from "./components/Breadcrumbs";
import Buttons from "./components/Buttons";
import Forms from "./components/Forms";
import Modals from "./components/Modals";
import Navs from "./components/Navs";
import Navbars from "./components/Navbars";
import Pagination from "./components/Pagination";
import Popovers from "./components/Popovers";
import Progress from "./components/Progress";
import Tables from "./components/Tables";
import Tabs from "./components/Tabs";
import Tooltips from "./components/Tooltips";
import Toasts from "./components/Toasts";

const RouteWithLoader = ({ element, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Preloader show={!loaded} />
      {loaded && element}
    </>
  );
};

const RouteWithSidebar = ({ element, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  const [showSettings, setShowSettings] = useState(() => {
    return localStorage.getItem('settingsVisible') !== 'false';
  });

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  };

  return (
    <>
      <Preloader show={!loaded} />
      {loaded && (
        <>
          <Sidebar />
          <main className="content">
            <Navbar />
            {element}
            <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
          </main>
        </>
      )}
    </>
  );
};

export default function AppRoutes() {


  return (
    <Routes>
      <Route path={NavRoutes.Settings.path} element={<RouteWithSidebar element={<Settings />} />} />
      <Route path={NavRoutes.Leaderboards.path} element={<RouteWithSidebar element={<Leaderboards />} />} />
      <Route path={NavRoutes.Tournaments.path} element={<RouteWithSidebar element={<Tournaments />} />} />
      <Route path={NavRoutes.Admin.path} element={<RouteWithSidebar element={<Admin />} />} />
      <Route path={NavRoutes.VerifyEmail.path} element={<RouteWithSidebar element={<VerifyEmail />} />} />
      <Route path={NavRoutes.Presentation.path} element={<RouteWithLoader element={<Presentation />} />} />
      <Route path={NavRoutes.Signin.path} element={<RouteWithLoader element={<Signin />} />} />
      <Route path={NavRoutes.Signup.path} element={<RouteWithLoader element={<Signup />} />} />
      <Route path={NavRoutes.ForgotPassword.path} element={<RouteWithLoader element={<ForgotPassword />} />} />
      <Route path={NavRoutes.ResetPassword.path} element={<RouteWithLoader element={<ResetPassword />} />} />
      <Route path={NavRoutes.NotFound.path} element={<RouteWithLoader element={<NotFoundPage />} />} />
      <Route path={NavRoutes.ServerError.path} element={<RouteWithLoader element={<ServerError />} />} />
      <Route path="*" element={<Navigate to={NavRoutes.NotFound.path} />} />
    </Routes>
  );
}
