import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from '@themesberg/react-bootstrap';
import { useAuth } from "./AuthContext"; // Use the AuthContext
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer  } from 'react-toastify'; // Import the toast from react-toastify

export default function RegisterModal({ show, handleClose }) {
  const { handleRegister } = useAuth(); // Use the AuthContext for registration handling
  const [username, setUsername] = useState(''); // State for the username
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // State for confirm password
  const [error, setError] = useState('');

  // Handle user registration
  const onRegister = async () => {
    // Check if passwords match
    if (password !== confirmPassword) {
      setError("Passwords do not match. Please try again.");
      return;
    }

    const response = await handleRegister(username, email, password); // Call handleRegister from AuthContext
    
    if (response.success) {
      // Show toast notification and close modal
      toast.success('Registration successful! Please check your email to verify your account.');
      //handleClose(); // Close the modal after registration
    } else {
      setError('Registration failed, please try again.');
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <ToastContainer />
      <Modal.Header closeButton>
        <Modal.Title>Create an Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formUsername">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formEmail" className="mt-3">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formPassword" className="mt-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formConfirmPassword" className="mt-3">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirm your password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Form.Group>

          {error && <div className="text-danger mt-3">{error}</div>}

          {/* Buttons aligned on the left and right */}
          <div className="d-flex justify-content-between mt-4">
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onRegister}>
              Register
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
