import React, { useState, useEffect } from 'react';
import { useAuth } from "../components/AuthContext";
import { useNavigate } from "react-router-dom";
import { Form, Button, Col, Tab, Tabs, ProgressBar } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';

// SendNotificationForm Component (from the old page)
const SendNotificationForm = ({ profileData, token }) => {
  const [formData, setFormData] = useState({
    target: 'user', // Default to user
    userIds: '',
    tournamentId: '',
    subject: '',
    message: '',
    messageType: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let identifiers;
    switch (formData.target) {
      case 'user':
        identifiers = formData.userIds.split(',').map(id => id.trim()).filter(Boolean);
        break;
      case 'tournament':
        identifiers = formData.tournamentId.trim(); // Single tournament ID
        break;
      default:
        return;
    }

    const data = {
      target: formData.target,
      identifiers, // This can be an array or single value depending on the target
      subject: formData.subject,
      message: formData.message,
      messageType: formData.messageType,
    };

    fetch(`${process.env.REACT_APP_API_URL}/send_notifications`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        toast.success('Notification sent successfully!');
      })
      .catch((error) => {
        toast.error('Failed to send notification.');
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group as={Col}>
        <Form.Label>Send Notification To:</Form.Label>
        <Form.Check
          type="radio"
          label="User ID(s)"
          name="target"
          value="user"
          checked={formData.target === 'user'}
          onChange={handleChange}
        />
        <Form.Check
          type="radio"
          label="Tournament ID"
          name="target"
          value="tournament"
          checked={formData.target === 'tournament'}
          onChange={handleChange}
        />
      </Form.Group>

      {formData.target === 'user' && (
        <Form.Group as={Col} controlId="formUserIds">
          <Form.Label>User ID(s)</Form.Label>
          <Form.Control
            type="text"
            name="userIds"
            value={formData.userIds}
            onChange={handleChange}
            placeholder="Enter User ID(s), comma separated"
          />
        </Form.Group>
      )}

      {formData.target === 'tournament' && (
        <Form.Group as={Col} controlId="formTournamentId">
          <Form.Label>Tournament ID</Form.Label>
          <Form.Control
            type="text"
            name="tournamentId"
            value={formData.tournamentId}
            onChange={handleChange}
            placeholder="Enter Tournament ID"
          />
        </Form.Group>
      )}

      <Form.Group as={Col} controlId="formSubject">
        <Form.Label>Subject</Form.Label>
        <Form.Control
          type="text"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          placeholder="Enter subject"
        />
      </Form.Group>

      <Form.Group as={Col} controlId="formMessage">
        <Form.Label>Message</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="Enter your message here"
        />
      </Form.Group>

      <Form.Group as={Col} controlId="formMessageType">
        <Form.Label>Message Type</Form.Label>
        <Form.Control as="select" name="messageType" value={formData.messageType} onChange={handleChange}>
          <option value="">Select a message type</option>
          <option value="info">Info</option>
          <option value="warning">Warning</option>
          <option value="alert">Alert</option>
          <option value="success">Success</option>
        </Form.Control>
      </Form.Group>

      <Button variant="primary" type="submit">Send Notification</Button>
    </Form>
  );
};


const ReplayUploadPage = ({profileData, token}) => {
  const [tournamentId, setTournamentId] = useState(''); // For tournament ID input
  const [uploadProgress, setUploadProgress] = useState(0); // Progress bar state
  const [isUploading, setIsUploading] = useState(false); // Uploading state
  const [games, setGames] = useState([{ file: null, gameNumber: 1 }]); // Game inputs

  // Handle adding a new game input field
  const addGameInput = () => {
    setGames([...games, { file: null, gameNumber: games.length + 1 }]);
  };

  // Handle removing a game input field
  const removeGameInput = (index) => {
    setGames(games.filter((_, i) => i !== index));
  };

  // Handle file change for a specific game input
  const handleFileChange = (e, index) => {
    const newGames = [...games];
    newGames[index].file = e.target.files[0]; // Only accept a single file per game
    setGames(newGames);
  };

  // Handle game number change
  const handleGameNumberChange = (e, index) => {
    const newGames = [...games];
    newGames[index].gameNumber = e.target.value;
    setGames(newGames);
  };

  // Handle input change for tournament ID
  const handleTournamentIdChange = (e) => {
    setTournamentId(e.target.value);
  };

  // Handle file upload
  const handleUpload = async () => {
    if (!tournamentId) {
      alert('Please enter a tournament ID.');
      return;
    }

    const formData = new FormData();
    formData.append('tournamentId', tournamentId); // Append the tournament ID

    games.forEach((game, index) => {
      if (game.file && game.gameNumber) {
        formData.append(`replay_${index}`, game.file); // Append each game file
        formData.append(`gameNumber_${index}`, game.gameNumber); // Append each game number
      }
    });

    setIsUploading(true); // Set uploading state to true

    try {
      // Upload files to the server
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/upload-replays`, 
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted); // Update progress bar as files upload
          },
        }
      );

      if (response.data.success) {
        alert('Replays uploaded successfully.');
      } else {
        alert('Error uploading replays: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error uploading replays:', error);
      alert('Error uploading replays.');
    }

    setIsUploading(false); // Reset uploading state after completion
  };

  return (
      <div>
        <h3>Upload Replay Files</h3>

        {/* Tournament ID input */}
        <Form.Group>
          <Form.Label>Tournament ID</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Enter tournament ID" 
            value={tournamentId} 
            onChange={handleTournamentIdChange} // Handle tournament ID input change
          />
        </Form.Group>

        {/* Dynamic game inputs */}
        {games.map((game, index) => (
          <div key={index} style={{ marginBottom: '15px' }}>
            <Form.Group>
              <Form.Label>Game {index + 1} - Replay File</Form.Label>
              <Form.Control 
                type="file" 
                onChange={(e) => handleFileChange(e, index)}
                accept=".replay" // Only accept .replay files
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Game Number</Form.Label>
              <Form.Control 
                type="number" 
                value={game.gameNumber}
                onChange={(e) => handleGameNumberChange(e, index)}
              />
            </Form.Group>

            <Button 
              variant="danger" 
              onClick={() => removeGameInput(index)} 
              disabled={games.length === 1} // Disable removing if only one game
            >
              Remove Game
            </Button>
          </div>
        ))}

        {/* Add another game button */}
        <Button variant="secondary" onClick={addGameInput}>
          Add Another Game
        </Button>

        {/* Upload button */}
        <Button 
          variant="primary" 
          onClick={handleUpload} 
          disabled={isUploading || games.length === 0 || games.some(game => !game.file)} // Disable if uploading or no files selected
        >
          {isUploading ? 'Uploading...' : 'Upload Replays'} {/* Button text changes during upload */}
        </Button>

        {/* Upload progress bar */}
        {isUploading && <ProgressBar animated now={uploadProgress} label={`${uploadProgress}%`} />}
      </div>
  );
};

const OrganizeLobby = ({profileData, token}) => {
  const [isAuth, setIsAuth] = useState(false);

  console.log('Profile Data:', profileData.role);
  console.log('Token:', token);
  const [formData, setFormData] = useState({
    title: 'Game Name (Fortnite, Apex Legends, etc.)',
    tier: '',
    requiredTickets: '1',
    type: 'Single Tournament',
    tournamentType: 'Squads/Trios/Duos/Solos',
    teamSize: '1,2,3,4',
    maxTotalTeams: '25',
    startTime: '',
    prizePool: '',
    region: '', // Add region
    platform: '', // Add platform
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form Data:', formData);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/create_tournament`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Ensure the token is included in the request if needed
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      
      if (response.ok) {
        alert('Tournament created successfully!');
        console.log(data);
      } else {
        throw new Error(data.message || 'Failed to create the tournament.');
      }
    } catch (error) {
      console.error('Error creating tournament:', error);
      alert(error.message);
    }
  };
  useEffect(() => {
    const checkRoles = async () => {
      if (profileData.role === 'admin' || profileData.role === 'organizer') {
        console.log(`User is a ${profileData.role}`);
        setIsAuth(true);
      } else {
        console.log('User is not an admin or an organizer');
        setIsAuth(true);
      }
    };
    checkRoles();
  }, [token, profileData.role]);
  return (
      <div className="content-layout">
      <h1 className='ailerons brand-color-blue content__title'>Organize Tournament</h1>
      {isAuth ? (

      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Tier</Form.Label>
          <Form.Select name="title" value={formData.title} onChange={handleChange}>
            <option value="">Select Game</option>
            <option value="Call of Duty">Call of Duty</option>
            <option value="Fortnite">Fortnite</option>
            <option value="Apex Legends">Apex Legends</option>

          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Label>Tier</Form.Label>
          <Form.Select name="tier" value={formData.tier} onChange={handleChange}>
            <option value="">Select Tier</option>
            <option value="Basic">Free</option>
            <option value="Copper">Copper</option>
            <option value="Bronze">Bronze</option>
            <option value="Silver">Silver</option>
            <option value="Gold">Gold</option>
            <option value="Diamond">Diamond</option>
            <option value="Platinum">Platinum</option>
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Label>Required Tickets</Form.Label>
          <Form.Control name="requiredTickets" value={formData.requiredTickets} onChange={handleChange} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Type</Form.Label>
          <Form.Control name="type" value={formData.type} onChange={handleChange} />
        </Form.Group>
        <Form.Group>
              <Form.Label>Region</Form.Label>
              <Form.Control name="region" value={formData.region} onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Platform</Form.Label>
              <Form.Control name="platform" value={formData.platform} onChange={handleChange} />
            </Form.Group>
        <Form.Group>
          <Form.Label>Tournament Type</Form.Label>
          <Form.Control name="tournamentType" value={formData.tournamentType} onChange={handleChange} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Team Size</Form.Label>
          <Form.Control name="teamSize" value={formData.teamSize} onChange={handleChange} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Max Total Teams</Form.Label>
          <Form.Control type="number" name="maxTotalTeams" value={formData.maxTotalTeams} onChange={handleChange} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Start Time</Form.Label>
          <Form.Control name="startTime" value={formData.startTime} onChange={handleChange} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Prize Pool</Form.Label>
          <Form.Control name="prizePool" value={formData.prizePool} onChange={handleChange} />
        </Form.Group>
        <Button variant="primary" type="submit">Start Tournament</Button>
      </Form>
      ) : (
        <h2 className="text-white">You are not authorized to organize a tournament.</h2>
      )}
      </div>
  );
};

// Main Admin Component
export const Admin = () => {
  const { token, isAuthenticated, profileData } = useAuth(); // Get the token and profileData from the AuthContext
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState("notify");

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/', { replace: true });
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (profileData?.role !== "admin") {
      navigate('/', { replace: true });
    }
  }, [profileData, navigate]);

  if (!isAuthenticated || profileData?.role !== "admin") {
    return null;
  }

  return (
    <div className="content-layout">
      <h1 id="page-title" className="ailerons brand-color-blue content__title">
        Admin Page
      </h1>
      <div className="content__body">
        <Tabs defaultActiveKey="notify" activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
          <Tab eventKey="notify" title="Notify">
            <SendNotificationForm profileData={profileData} token={token} />
          </Tab>
          <Tab eventKey="UploadTournaments" title="Upload Replay">
            <ReplayUploadPage profileData={profileData} token={token} />
          </Tab>
          <Tab eventKey="CreateTournament" title="Tournament">
            <OrganizeLobby profileData={profileData} token={token} />
          </Tab>
        </Tabs>
      </div>
      <Toaster /> {/* Optional notification toaster */}
    </div>
  );
};

export default Admin;
