
export const NavRoutes = {
    Presentation: { path: "/" },
    Leaderboards: { path: "/leaderboards" },
    Tournaments: { path: "/tournaments" },
    Settings: { path: "/settings" },
    Admin: { path: "/admin" },
    VerifyEmail: { path: "/verify-email" },
    NotFound: { path: "/404" },
    ServerError: { path: "/500" },
    Signin: { path: "/sign-in" },
    Signup: { path: "/sign-up" },
    ForgotPassword: { path: "/examples/forgot-password" },
    ResetPassword: { path: "/examples/reset-password" }
};